<template>
  <div class="inner-pages hd-white">
    <div id="wrapper">
      <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
        <div class="container res-show-mobile">
           <h2 class="text-center mb-4 mt-3">BRAND AMBASSADORS EXPLAINED</h2>
  <div class="row">
    
    <div class="col-sm text-center ">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Custom Promo Code</strong>

                     <p
                    style="
                      text-transform: initial;
                      margin-left: 2px;
                      margin-right: 4px;
                      padding-right: 35px;
                      font-size: 1rem;
                      padding-left: 35px;
                      font-family: Montserrat, sans-serif;
                    "
                    class="mt-4"
                  >
                    Simply apply to become a Vencasa brand ambassador. Once
                    accepted, we then provide you with a custom discount code.
                  </p>

    </div>
    <div class="col-sm text-center">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Promote Vencasa</strong>

                     <p
                    style="
                      text-transform: initial;
                      margin-left: 2px;
                      margin-right: 4px;
                      padding-right: 35px;
                      font-size: 1rem;
                      padding-left: 35px;
                      font-family: Montserrat, sans-serif;
                    "
                    class="mt-4"
                  >
                    Share your custom discount code with your followers, friends or family. We can provide you with marketing material to help should it be needed.
                  </p>

    </div>
    <div class="col-sm text-center">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Receive Commission</strong>

                     <p
                    style="
                      text-transform: initial;
                      margin-left: 2px;
                      margin-right: 4px;
                      padding-right: 35px;
                      font-size: 1rem;
                      padding-left: 35px;
                      font-family: Montserrat, sans-serif;
                    "
                    class="mt-4"
                  >
                    You receive a commission for every personyou refer who goes on to list a property with Vencasa. Royalties paid every month.
                  </p>

    </div>
  </div>
</div>






  </div>
      <div id="login">
        <div class="login">
          <h2 style="font-size:45px; font-weight:700;" class="text-center res-hide-mobile">BRAND AMBASSADORS EXPLAINED</h2>
          <section
            style="margin-top: 90px;"
            _ngcontent-bgi-c3=""
            class="featured-boxes-area res-s-dnone"
          >
            <div class="title container mt-5">

            <div style="" class="row justify-md-center">
                <div class="col-md-12">
              <ul style="" class="progress-steps">
                <li class="active">
                  <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; 
                    "
                    class="mb-5"
                    >Custom Promo Code</strong
                  >
                  <p
                    style="
                      text-transform: initial;
                      margin-left: 2px;
                      margin-right: 4px;
                      padding-right: 35px;
                      font-size: 1rem;
                      padding-left: 35px;
                      font-family: Montserrat, sans-serif;
                    "
                    class="mt-5"
                  >
                    Simply apply to become a Vencasa brand ambassador. Once
                    accepted, we then provide you with a custom discount code.
                  </p>
                </li>

                <li>
                  <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                    class="mb-5"
                    >Promote Vencasa</strong
                  >
                  <p
                    style="
                      text-transform: initial;
                      margin-left: 2px;
                      margin-right: 4px;
                      padding-right: 35px;
                      font-size: 1rem;
                      padding-left: 35px;
                      font-family: Montserrat, sans-serif;
                    "
                    class="mt-5"
                  >
                    Share your custom discount code with your followers, friends
                    or family. We can provide you with marketing material to
                    help should it be needed.
                  </p>
                </li>
                

                <li>
                  <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                    class="mb-5"
                    >Receive Commission</strong
                  >
                  <p
                    style="
                      text-transform: initial;
                      margin-left: 2px;
                      margin-right: 4px;
                      padding-right: 35px;
                      font-size: 1rem;
                      padding-left: 35px;
                      font-family: Montserrat, sans-serif;
                    "
                    class="mt-5"
                  >
                    You receive a commission for every personyou refer who goes
                    on to list a property with Vencasa. Royalties paid every
                    month.
                  </p>
                </li>
              </ul>
              </div>
              </div>
            </div>
          </section>
         <div style="justify-content: center;" class="row">
            <form class="mt-5 res-form-set"
           
            @submit.prevent=""
            autocomplete="off"
          >
          <h3 class="mt-3 mb-5 text-center">BECOME A BRAND AMBASSADOR</h3>
            <div class="form-group">
                <label>First Name</label>
              <input
                style="height: 50px"
                class="form-control"
                
                type="text"
              />
              <i class="ti-user"></i>
            </div>

 <div class="form-group">
                <label>Last Name</label>
              <input
                style="height: 50px"
                class="form-control"
                
                type="text"
              />
              <i class="ti-user"></i>
            </div>
            <div class="form-group">
                <label>Email</label>
              <input
                style="height: 50px"
                class="form-control"
               
                type="email"
              />
              <i class="icon_mail_alt"></i>
            </div>
            <div id="pass-info" class="clearfix"></div>
            <button
              @click="register()"
              style="outline: none; color: white; width:100% !important; border-radius: 5px !important;"
              class="btn_1 rounded full-width add_top_30 res-button-how"
            >
              Register Now
            </button>
          </form>
           </div>
        </div>
      </div>
      <div class="login-and-register-form modal">
        <div class="main-overlay"></div>
        <div class="main-register-holder">
          <div class="main-register fl-wrap">
            <div class="close-reg"><i class="fa fa-times"></i></div>
            <h3>
              Welcome to <span>Find<strong>Houses</strong></span>
            </h3>
            <div class="soc-log fl-wrap">
              <p>Login</p>
              <a href="#" class="facebook-log"
                ><i class="fa fa-facebook-official"></i>Log in with Facebook</a
              >
              <a href="#" class="twitter-log"
                ><i class="fa fa-twitter"></i> Log in with Twitter</a
              >
            </div>
            <div class="log-separator fl-wrap"><span>Or</span></div>
            <div id="tabs-container">
              <ul class="tabs-menu">
                <li class="current"><a href="#tab-1">Login</a></li>
                <li><a href="#tab-2">Register</a></li>
              </ul>
              <div class="tab">
                <div id="tab-1" class="tab-contents">
                  <div class="custom-form">
                    <form method="post" name="registerform">
                      <label>Username or Email Address * </label>
                      <input name="email" type="text" value="" />
                      <label>Password * </label>
                      <input name="password" type="password" value="" />
                      <button type="submit" class="log-submit-btn">
                        <span>Log In</span>
                      </button>
                      <div class="clearfix"></div>
                      <div class="filter-tags">
                        <input id="check-a" type="checkbox" name="check" />
                        <label for="check-a">Remember me</label>
                      </div>
                    </form>
                    <div class="lost_password">
                      <a href="#">Lost Your Password?</a>
                    </div>
                  </div>
                </div>
                <div class="tab">
                  <div id="tab-2" class="tab-contents">
                    <div class="custom-form">
                      <form
                        @submit.prevent=""
                        name="registerform"
                        class="main-register-form"
                        id="main-register-form2"
                      >
                        <label>First Name * </label>
                        <input name="name" type="text" value="" />
                        <label>Second Name *</label>
                        <input name="name2" type="text" value="" />
                        <label>Email Address *</label>
                        <input name="email" type="text" value="" />
                        <label>Password *</label>
                        <input name="password" type="password" value="" />
                        <span class="field"></span>
                        <label>Confirm Password *</label>
                        <input name="password" type="password" value="" />
                        <button type="submit" class="log-submit-btn">
                          <span>Register</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
    },
  },
  methods: {
    register() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        };
        this.$store.dispatch("brandAmbassador", data).then((res) => {
          if (res == true) {
            this.$v.$reset()
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.$store.commit("setMessage", "Email sent");
          } else
            this.$store.commit(
              "setMessage",
              "Email was not sent"
            );
        });
      }
    },
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }

  .res-show-mobile{
    display: none !important;
  }
}

@media (max-width: 700px) {
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

.res-hide-mobile{
  display: none !important;
}



  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }
  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    margin-bottom: 10px !important;
    width: 250px !important;
    padding: 13px !important;
    margin-left: 20px !important;
  }
  .res-button-prop {
    padding: 13px 49px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 802px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }

  .res-button-how {
    float: right !important;
  }
  .res-form-set {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}
.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 33%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
  height: 4.5em;
  content: counter(step);
  counter-increment: step;
  line-height: 4em;
  border: 6px solid green;
  display: block;
  text-align: center;
  margin: 10px auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}

.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}

#login .login,
#register .login {
  width: 100%;
  padding-top: 0px;
}
.form-control {
  border-radius: 8px;
}
h2 {
  text-transform: inherit;
  font-size: 1.3em;
}

p {
  font-weight: 500;
}
</style>
